import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/classnames';
import { Router, useRouter } from 'next/router';
import Container from '@mui/material/Container';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Header from 'components/Globals/Layout/Header/Header';
import Footer from 'components/Globals/Layout/Footer';
import Breadcrumbs from 'components/Breadcrumbs';
import { getCookie } from 'utils/cookie';
import { useActiveProfileData } from 'utils/hooks/useAuthenticatedUser';
import { IS_IDENTITY_VERIFICATION, IS_ACTION_CENTER, IS_CHECKOUT_ACTIVE } from 'constants/cookieConstants';

import { useIntermediatePopup } from 'utils/hooks/useIntermediatePopup';
import useScrollDirection from 'utils/hooks/useScrollDirection';
import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import useAppContext from 'utils/hooks/useAppContext';
import SEOComponent from '../NextSeo';
import { useNotificationPopup } from '../../utils/hooks/useNotificationPopup';
import { useDialogs } from '../../utils/hooks/useDialogs';
import useMarkerIO from '../../utils/hooks/useMarkerIO';
import useRenderingType from '../../utils/hooks/useRenderingType';
import styles from './Layout.module.scss';

const SubscriptionNudges = dynamic(() => import('components/Globals/SubscriptionModels/SubscriptionNudges'));
const ProfilePendingAccessPopup = dynamic(() => import('../ProfilePendingAccessPopup'));
const CompleteRegistrationPopup = dynamic(() => import('../CompleteRegistrationPopup'));

const NoCastingToolPopUp = dynamic(() => import('components/Globals/SubscriptionModels/NoCastingToolPopUp'));
const SwitchProfilePopUp = dynamic(() => import('components/Globals/SubscriptionModels/SwitchProfilePopUp'));
const NoSubscriptionPopUp = dynamic(() => import('components/Globals/SubscriptionModels/NoSubscriptionPopUp'));
const AdminPopUp = dynamic(() => import('components/Globals/SubscriptionModels/AdminPopUp'));

const UpgradeToCastingToolPopUp = dynamic(() =>
  import('components/Globals/SubscriptionModels/UpgradeToCastingToolPopUp/UpgradeToCastingToolPopUp'),
);
const ReactivateSubscriptionPopUp = dynamic(() =>
  import('components/Globals/SubscriptionModels/ReactivateSubscriptionPopUp/ReactivateSubscriptionPopUp'),
);
const UpgradeSubscriptionPopUp = dynamic(() =>
  import('components/Globals/SubscriptionModels/SubscriptionUpgradeModal/SubscriptionUpgradeModal'),
);
const AccountsWrapper = dynamic(() => import('components/Globals/Accounts/AccountsWrapper'));
const Checkout = dynamic(() => import('components/Globals/Accounts/Checkout'));

const RedirectionPopup = dynamic(() => import('../RedirectionPopup'));

const SetupPassword = dynamic(() => import('components/Globals/Accounts/Common/SetupPassword/SetupPassword'));
const IdentityVerification = dynamic(() => import('components/Globals/Accounts/IdentityVerification'));
const AudienceOnboarding = dynamic(() => import('components/Globals/Accounts/Common/AudienceOnboarding'));
const ActionCenter = dynamic(() => import('components/Globals/Accounts/ActionCenter'));
const ApprovedHiddenProfileModal = dynamic(() =>
  import('components/Globals/Accounts/Common/ApproveHiddenProfileModal'),
);
const IntermediatePopup = dynamic(() => import('components/IntermediateDialog'));

const headerSection = {
  section: SECTIONS.GLOBAL_HEADER,
};

const headerNavigationTrackingData = {
  ...headerSection,
  component: COMPONENTS.NAVIGATION,
  subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
};

const headerSearchTrackingData = {
  ...headerSection,
  component: COMPONENTS.GLOBAL_SEARCH,
};
export const LayoutContent = ({ children, variant, className, noPadding }) => (
  <div
    className={cn(
      {
        [styles.layoutContent]: variant === 'top',
        [styles.layoutContentReg]: variant !== 'top',
        [styles.noPadding]: noPadding !== false,
      },
      className,
    )}
  >
    {children}
  </div>
);

LayoutContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  variant: PropTypes.oneOf(['top', 'regular']),
  className: PropTypes.string,
  noPadding: PropTypes.bool,
};

LayoutContent.defaultProps = {
  variant: 'top',
  noPadding: false,
};

const Layout = ({
  className,
  children,
  title,
  customBreadcrumbs,
  seo,
  containerClassName,
  preloadAssets = [],
  hideNotificationBanner,
  onlyHeader,
  disableQuery,
  shouldHideHeader,
  containerMaxWidth,
  pageQueryList,
}) => {
  const {
    subscriptionNudgesPopup,
    noSubscriptionPopup,
    pendingAccessPopup,
    archiveDialog,
    upgradeToCastingToolPopup,
    upgradeSubscriptionPopUp,
    reActivatePopUp,
    switchProfilePopUp,
    completeRegistrationPopup,
    adminPopUp,
  } = useNotificationPopup();
  const {
    isLoginDialog,
    resetDialogState,
    isNewUser,
    isOpenSetupPassword,
    isOpenIdentityVerification,
    isOpenCheckout,
    isOpenActionCenter,
    isAudienceOnboardingModal,
    approvedHiddenProfile,
    setApprovedHiddenProfile,
  } = useDialogs();
  const { intermediatePopup } = useIntermediatePopup();
  const router = useRouter();
  const { isLoggedIn } = useAppContext();
  const activeProfile = useActiveProfileData();
  const isOpenIdentityVerificationModal = getCookie(IS_IDENTITY_VERIFICATION);
  const isActionCenterCookie = getCookie(IS_ACTION_CENTER);
  const isCheckoutCookies = getCookie(IS_CHECKOUT_ACTIVE);
  const isOnboarding = router?.query?.onboarding;
  const renderingType = useRenderingType();
  const [showBanner, setShowBanner] = useState(true);

  useScrollDirection({
    onScrollDown: () => {
      if (showBanner) {
        setShowBanner(false);
      }
    },
    onScrollUp: () => {
      if (!showBanner) {
        setShowBanner(true);
      }
    },
    threshold: 0,
  });

  Router.events.on('routeChangeStart', () => {
    if (typeof resetDialogState === 'function') {
      resetDialogState();
    }
  });

  useMarkerIO(isLoggedIn);

  function renderPreloadAssetsLink() {
    return preloadAssets.map((asset, index) => (
      <link key={`preloaded-${index}`} rel="preload" as={asset.type} href={asset.src} />
    ));
  }

  return (
    <div className={cn(styles.root, className)}>
      <Head>
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
        {/* Apple touch Icon */}
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png" />

        {/* Splash Screens */}
        <link
          href="/splashscreens/iphone5_splash.png"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphone6_splash.png"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphoneplus_splash.png"
          media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphonex_splash.png"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphonexr_splash.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphonexsmax_splash.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipad_splash.png"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipadpro1_splash.png"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipadpro3_splash.png"
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipadpro2_splash.png"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />

        {/* Manifest file - PWA */}
        <link href="/manifest.json" rel="manifest" />
        <meta key="rendering-type" name="rendering-type" content={renderingType} />
        {renderPreloadAssetsLink(preloadAssets)}
      </Head>
      <SEOComponent
        title={title ? `${title} - Operabase` : 'Operabase'}
        {...seo}
        disableQuery={disableQuery}
        pageQueryList={pageQueryList}
      />
      {!shouldHideHeader && (
        <Header
          isLoggedIn={isLoggedIn}
          onlyHeader={onlyHeader}
          activeProfile={activeProfile}
          hideNotificationBanner={hideNotificationBanner}
          navigationTrackingData={headerNavigationTrackingData}
          searchTrackingData={headerSearchTrackingData}
        />
      )}
      {customBreadcrumbs && !onlyHeader && (
        <div
          className={cn(styles.logoBreadcrumbWrapper, {
            [styles.logoBreadcrumbWrapper_nonSticky]: !showBanner,
          })}
        >
          <div className={styles.logoBreadcrumbSection}>
            <Breadcrumbs customBreadcrumbs={customBreadcrumbs} />
          </div>
        </div>
      )}
      <Container className={cn(styles.rootContainer, containerClassName)} maxWidth={containerMaxWidth}>
        <div className={cn(styles.content, { [styles.maxWidthNone]: containerMaxWidth === false })}>{children}</div>
        {adminPopUp?.isOpen && <AdminPopUp isOpen={adminPopUp?.isOpen} />}
        {intermediatePopup?.isOpen && <IntermediatePopup />}
        {subscriptionNudgesPopup?.isOpen && <SubscriptionNudges />}
        {noSubscriptionPopup && <NoSubscriptionPopUp isOpen={noSubscriptionPopup} />}
        {pendingAccessPopup && <ProfilePendingAccessPopup isOpen={pendingAccessPopup} />}
        {isLoginDialog?.isOpen && <AccountsWrapper isNewUser={isNewUser} isModal />}
        {archiveDialog && <NoCastingToolPopUp isOpen={archiveDialog} />}
        {reActivatePopUp.isOpen && <ReactivateSubscriptionPopUp isOpen={reActivatePopUp.isOpen} />}
        {upgradeToCastingToolPopup && <UpgradeToCastingToolPopUp isOpen={upgradeToCastingToolPopup} />}
        {upgradeSubscriptionPopUp.isOpen && <UpgradeSubscriptionPopUp />}
        {switchProfilePopUp && <SwitchProfilePopUp isOpen={switchProfilePopUp} />}
        {completeRegistrationPopup && <CompleteRegistrationPopup isOpen />}
        {(isOpenActionCenter || isActionCenterCookie) && <ActionCenter />}
        {approvedHiddenProfile && (
          <ApprovedHiddenProfileModal profile={approvedHiddenProfile} onClose={() => setApprovedHiddenProfile(false)} />
        )}
        <RedirectionPopup />
        {(isOpenCheckout || isCheckoutCookies) && !upgradeSubscriptionPopUp.isOpen && <Checkout isModal />}
        {isOpenSetupPassword && <SetupPassword />}
        {(isOpenIdentityVerification || isOpenIdentityVerificationModal) && <IdentityVerification />}
        {(isAudienceOnboardingModal || isOnboarding) && <AudienceOnboarding />}
      </Container>
      {!onlyHeader && <Footer isLoggedIn={isLoggedIn} />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  title: PropTypes.string,
  customBreadcrumbs: PropTypes.array,
  seo: PropTypes.object,
  onlyHeader: PropTypes.bool,
  disableQuery: PropTypes.bool,
  shouldHideHeader: PropTypes.bool,
  containerMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Layout.defaultProps = {
  shouldHideHeader: false,
  containerMaxWidth: 'lg',
};

export default Layout;
